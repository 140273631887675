@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #222222;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.neue-600 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.neue-600 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.neue-800 {
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
}

.oswald {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

.bg-farm {
  background-image: url('https://images.pexels.com/photos/1198507/pexels-photo-1198507.jpeg?cs=srgb&dl=pexels-jonathan-petersson-1198507.jpg&fm=jpg');
  background-size: cover;
  background-position: center;
}

.masonry-grid {
  /* column-count: 4; */
  /* Adjust the number of columns based on your preference */
  /* column-gap: 1em; */
  /* column-count: 2 !important; */
  grid-template-columns: repeat(4, minmax(0, 1fr));
  /* column-count: 4 ; */
  padding-right: 4rem;
  padding-left: 4rem;
}

.masonry-grid-item {
  /* break-inside: avoid; */
  /* Prevents items from being split across columns */
  /* margin-bottom: 1em; */
}

.sponsorship-grid{
  margin-bottom: 4rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.home-header{
  
  backdrop-filter: blur(2px) ;
  --tw-backdrop-blur: blur(2px) ;
  background-color: #00000047;
}


.goatfarm-text{
  display:none;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  .featured-img{
    height: 300px ;
  }
  /* Safari-specific CSS goes here */
}



@media only screen and (max-width: 767px) {
  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    .featured-img{
      height: 100% !important;
    }
  }

  .product-imgs-container{
    justify-content: flex-start !important;
  }

  .product-mini-image{
    padding-left: 1rem;
  }

  /* Styles specific to mobile devices */
  .home-header {
    /* flex-direction: column; */
    width: 80%;
    min-width: auto;
    max-width: auto;
    padding-top: 0rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 0rem;
    backdrop-filter: blur(0px);
    --tw-backdrop-blur: blur(0px);
    background-color: #00000047;
    /* border-radius: ; */
  }

  .home-header-inner {
    flex-direction: column;
    width: 100%;
  }

  .iframe-border {
    /* display: none; */
    z-index: 50;
    width: calc(100% - 2rem);
    height: 212px;
    /* width: 100%; */
    border-left: 35px solid #cc262c !important;
    border-top: 35px solid #cc262c !important;
    border-right: 35px solid #cc262c !important;
    border-bottom: 35px solid #cc262c !important;
    /* border: 5px; */
    border-width: 27px;
  }

  .stream-loading-container{
    height: 193px;
    width: 313px;
  }

  .home-header-outter {
    padding-bottom: 0rem;
    height: auto;
  }

  .home-header-left {
    width: 100%;
    display: none;
  }

  .home-header-right {
    width: 100%;
  }

  #youtube-iframe {
    display: none;
  }

  #mobile-iframe {
    display: flex;
    height: 24vh;
  }

  .home-container {
    /* height: 100vh; */
  }
  .nav-grid-container{
    width: 100%;
  }

  .nav-grid-container-inner {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    height: auto;
    gap: 0.5rem;
  }

  .nav-grid-outter {
    margin-top: 0rem;
    /* margin-bottom: 1rem; */
    /* margin-bottom: 0.75rem; */
    height: auto;

  }

  .nav-grid-item {
    min-height: 175px;
  }

  .contact-animal {
    display: none;
    width: 100%;
  }

  .contact-animal-mobile{
    display: flex;
  }

  .contact-form-shell {
    padding-top: 0rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
  }

  .contact-shell {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
  }

  .contact-outer {
    height: auto;
  }

  .about-shell {
    flex-direction: column;
  }

  .about-outer {
    height: auto;
  }

  .about-right {
    width: 100%;
    padding: 0rem;
  }

  .about-social-links {
    flex-direction: column;
  }

  .about-container {
    padding-top: 2rem;
    border-top: 0px;
  }

  .masonry-grid {
    /* column-count: 2 !important; */
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .shop-container {
    height: auto;

  }

  .nav-link {
    font-size: 0.75rem;
  }

  .search-nav {
    display: none;
  }

  .product-container {
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .product-content {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .product-outer {
    height: auto;
  }

  .add-to-cart-btn {
    width: 100%;
  }

  .nav-links {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .nav-outer {
    padding-left: 1rem;
    padding-right: 1rem;

  }

  .nav-logo {
    width: 100% !important;
    height: auto;
  }

  .stream-container {
    flex-direction: column;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0rem;
  }

  .stream-inner {
    margin-top: 0rem;
    margin-bottom: 2rem;
  }

  .stream-plot {
    width: 100%;
  }

  .product-image-container{
    width: 100%; margin-bottom: 2rem;
    height: auto;
  }

  .alt-search-bar{
    width:100px;
  }

  .cart-container{
    justify-content: start;
  }

  .cart-80{
    height: 70%;
  }

  .alt-serach-container{
    margin-top:0.25rem;
    margin-right:0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .alt-serach-result-title{
    font-size: 0.7rem;
  }

  .goat-interactive-icon{
    margin-top:1rem;
    margin-bottom:-2rem;
    margin-left: 8rem;
  }

  .live-box{
    margin-top:-7rem;
    right: 19%;
  }

  .shop-goat-container{
    height: 41vh;
    margin-top:-2.5rem;
    margin-bottom:-2.5rem;
    /* margin-bottom: -4rem; */
  }

  .live-panel{
    margin-top:2rem;
    margin-right: 2rem;
  }

  .horse-cow-image-home{
    width: 100%;
  }

  .goat-obj-inner{
    display: flex;
    height: 150px;
  }

  .goatfarm-text{
    display:flex;
  }

  .portrait{
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  .grid-imgs{
    width: 90%;
  }

  .product-img-main{
    height: 100%;
    width: auto;
    margin-top: 0rem !important;
  }

  .goatfarm-text {
    padding-bottom: 2rem;
  }
  
}